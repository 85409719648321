<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="setReCall">
      <div class="row">
        <div class="col-12">

          <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('comment') }">
              <textarea class="form-control"
                        maxlength="3000"
                        id="comment"
                        rows="3"
                        placeholder="Комментарий"
                        v-model="form.comment">
              </textarea>
            <HasError :form="form" field="comment" />
          </div>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

          <br />

          <Button :disabled="form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>
        </div>
      </div>
    </form>
  </transition>
</template>

<script>
import Form from "vform";

Form.axios = API.apiClient;

export default {
  name: "recall-task-form",

  components: {
    Button, HasError, AlertError, AlertErrors,
  },

  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: new Form({
      comment: null,
    }),
  }),

  methods: {
    async setReCall() {
      await this.form.put('/tasks/recall/' + this.task.id).then(() => {
        showSuccess();
        this.EventBus.$emit("hide-recall-task-modal");
        this.EventBus.$emit('card-filter-changed');
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style>

</style>
